<template>
    <div>
        <Footer />
        <div class="content">
            <p class="contenTitle">密码找回</p>
            <p class="cross"></p>
            <p style="text-align: center;">
                <input type="text" v-model="telephone" class="contentInput" placeholder="您的手机号" onfocus="this.placeholder=''" onblur="this.placeholder='您的手机号'">
            </p>
            <p style="text-align: center;">
                <input type="text" v-model="email" class="contentInput" placeholder="您注册时预留的邮箱" onfocus="this.placeholder=''" onblur="this.placeholder='您注册时预留的邮箱'">
            </p>
            <p style="text-align: center;">
                <input type="text" v-model="id_num" class="contentInput" placeholder="您的身份证号" onfocus="this.placeholder=''" onblur="this.placeholder='您的身份证号'">
            </p>
            <p style="text-align: center;">
                <el-button type="info" id="contentBut" @click="password">找回密码</el-button>
            </p>
            <div class="contentText">
                <span >大赛注册及报名信息收集仅用于大赛组委会审核与参赛，我们将高度重视您的个人信息安全，并采取符合法律法规的安全措施，保护您的个人信息，防止发生个人信息的泄露、丢失、等安全风险。</span>
            </div>
        </div>
        <Bottom />
    </div>
</template>
<script>
import Footer from '@/components/Footer/Footer.vue'
import Bottom from '@/components/Bottom/Bottom.vue'
import {getLogin} from "api/apis.js"
import { method } from 'lodash';
export default {
    name: 'Register',
    components: {
        Footer,
        Bottom
    },
    data() {
        return {
            telephone:"",
            email:"",
            id_num:"",
        }
    },
    created() { },
    mounted() { },
    methods: {
        password(){
            let dataobj = new FormData();
                dataobj.append('telephone',this.telephone);
                dataobj.append('email',this.email);
                dataobj.append('id_num',this.id_num);
                getLogin.Psword(dataobj).then(res=>{
                    console.log(res)
                    if(res.code == 200){
                        this.$message({
                            message: '重置成功，密码为身份证号后6位"',
                            type: 'success'
                        });
                    }else{
                        this.$message.error('您输入的数据有误！');
                    }
                })
        }
    }

}
</script>
<style lang="scss" scoped>
@import './back.less';
</style>
